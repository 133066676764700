
import React, { useEffect } from "react";
import { createBrowserHistory } from "history";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from "react-redux";

// import components
import Admin from "./components/layouts/Admin";
import ConditionRoute from './components/Route/ConditionRoute';
import UsersList from './pages/UsersList/UsersList';
import Usersedit from './pages/UsersList/useredit';
import LoginHistory from './pages/LoginHistory/loginHistory';
import EditProfile from './pages/UserProfile/EditProfile';
import securityType from './pages/Settings/securityType';
import { AdminList, AdminAdd, AdminEdit } from './pages/Admin'
import ReportList from './pages/Report/ReportList';
import presale from './pages/Presale/Presalesetting';
import LoginPage from './pages/LoginPage/Login';
import ForgotPage from './pages/ForgotPage/ForgotPassword';
import changePassword from './pages/ForgotPage/changePassword';
import Dashboard from './pages/Dashboard/Dashboard';
import UserProfile from './pages/UserProfile/UserProfile'


//Bid
import Bidpage from "./pages/Bids/Bids"


//Token 
import TokenList from "./pages/Token/Tokenlist"
import TokenView from "./pages/Token/Tokenview"

import MarketPlace from './pages/MarketPlace/MarketPlace'

import Trandinglists from "./pages/Token/Trandinglists"
import Featuredlists from "./pages/Token/Featuredlists"
import Collectionlists from "./pages/Token/Collectionlists"
import reportcollection from "./pages/Report/ReportCollection"
import CMS from './pages/Settings/Cms'
import verificationlist from './pages/request/verificationlist'
import store from './store';

import "assets/css/material-dashboard-react.css?v=1.9.0";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// import LoginHistory from "../deepliquidity_exchange_server/modals/loginHistory";

import globalStyle from './style/global.style';

// import action 
import { decodeJwt } from './actions/jsonWebToken'

const history = createBrowserHistory();

const App = () => {
    const { isAuth } = store.getState().currentUser;

    useEffect(() => {
        if (isAuth != true && localStorage.admin_token) {
            decodeJwt(localStorage.admin_token, store.dispatch)
        }
    }, [])

    return (
        <Provider store={store} >
            <ThemeProvider theme={globalStyle}>
                <ToastContainer />
                {/* <Router history={history} basename={process.env.REACT_APP_BASENAME || "/ulosdewvhref"}> sfgytndvfger */}
                <Router history={history} basename={"/sfgytndvfger"}>

                    <Switch>

                        {/* Auth Route */}
                        <ConditionRoute path="/login" component={LoginPage} type={"auth"} />
                        <ConditionRoute path="/forgot" component={ForgotPage} type={"auth"} />
                        <ConditionRoute path="/changepassword/:authToken" component={changePassword} type={"auth"} />

                        <ConditionRoute path="/Featuredlists" component={Featuredlists} layout={Admin} type={"private"} />
                        <ConditionRoute path="/Trendinglists" component={Trandinglists} layout={Admin} type={"private"} />
                        <ConditionRoute path="/collectionlist" component={Collectionlists} layout={Admin} type={"private"} />
                        {/* Private Route */}
                        <ConditionRoute path="/dashboard" component={Dashboard} layout={Admin} type={"private"} store={store} />
                        <ConditionRoute exact path="/list" component={AdminList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/add" component={AdminAdd} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/eidt/:userId" component={AdminEdit} layout={Admin} type={"public"} />

                        <ConditionRoute exact path='/market-place' component={MarketPlace} layout={Admin} type={"private"} />

                        {/* Token  */}

                        <ConditionRoute exact path="/TokenList" component={TokenList} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/TokenView/:userId" component={TokenView} layout={Admin} type={"public"} />

                        <ConditionRoute exact path="/ReportList" component={ReportList} layout={Admin} type={"private"} />

                        {/* Biddd */}
                        <ConditionRoute exact path="/bidlist" component={Bidpage} layout={Admin} type={"private"} />
                        <ConditionRoute exact path="/presale" component={presale} layout={Admin} type={"private"} />
                        <ConditionRoute path="/user" component={UserProfile} layout={Admin} type={"private"} />
                        <ConditionRoute path="/userList" component={UsersList} layout={Admin} type={"private"} />
                        <ConditionRoute path="/useredit/:userId" component={Usersedit} layout={Admin} type={"public"} />

                        {/* Setting */}
                        <ConditionRoute path="/securityType" component={securityType} layout={Admin} type={"private"} />
                        <ConditionRoute path="/reportcollection" component={reportcollection} layout={Admin} type={"private"} />

                        <ConditionRoute path="/editprofile" component={EditProfile} layout={Admin} type={"private"} />

                        <Redirect from="/" to="/login" />

                        {/* <Route path="/admin" component={Admin} /> */}




                    </Switch>
                </Router>
            </ThemeProvider>

        </Provider>
    )

}

export default App;