import { combineReducers } from "redux";
import authReducer from "./authReducer";
// import errorReducer from "./errorReducers";
import currentUser from './currentUser';

import modal from './modal';

export default combineReducers({
    auth: authReducer,
    currentUser,
    modal:modal,
});