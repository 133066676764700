// @material-ui/icons
import {
  Dashboard,
  Person,
  LibraryBooks,
  List,
  Equalizer,
  Timeline,
  Settings,
  Money,
  
  History,
  Email,
  Help,
  LiveHelp,
  AccountBalanceWallet,
  AccountBalance,
  ExitToApp,
  ArrowForwardIcon
  
} from "@material-ui/icons";

import AdjustIcon from '@material-ui/icons/Adjust';

const dashboardRoutes = [
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "login",
    path: "/login",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "change-password",
    path: "/changepassword/:authToken",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "security",
    path: "/securityType",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  {
    id: "edit-profile",
    path: "/editprofile",
    isSideMenu: false,
    isEdit: false,
    isRestricted: 'common'
  },
  /* Dashboard */
  {
    path: "#",
    id: "dashboard",
    name: "Dashboard",
    icon: List,
    path: "/dashboard",
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    isRestricted: 'common',
    child: [
      {
        name: "Dashboard Lists",
        path: "/dashboard",
        icon: Person,
        isRestricted: 'common',
      },
     
    ]
    
  },
  /*Admin  */
  {
    id: "admin",
    path: "/list",
    name: "Admin Management",
    icon: List,
    isSideMenu: true,
    isEdit: true,
    isRestricted: 'multi',
    child: [
      {
        id: 'add',
        path: "/add",
      },
      {
        id: 'edit',
        path: "/edit/:userId",
      }
    ]
  },
  
  {
    path: "#",
    id: "Bid",
    name: "Bid",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Bid Lists",
        path: "/bidlist",
        icon: Person,
      },
     
    ]
  },
  {
    path: "#",
    id: "collection",
    name: "Collection Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
       {
        name: "Collection Verification",
        path: "/collectionlist",
        icon: Person,
      },
      {
        name: "Featured Lists",
        path: "/Featuredlists",
        icon: Person,
      },
      // {
      //   name: "Trending Lists",
      //   path: "/Trendinglists",
      //   icon: Person,
      // },
     
    ]
  },
  {
    path: "#",
    id: "token",
    name: "Token Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "Token Lists",
        path: "/TokenList",
        icon: Person,
      },
      {
        name: "NFT Report Lists",
        path: "/ReportList",
        icon: Person,
      },
      {
        name: "Collection Report Lists",
        path: "/reportcollection",
        icon: Person,
      }
     
    ]
  },
  {
    path: "#",
    id: "user",
    name: "User Management",
    icon: List,
    type: "multi",
    isEdit: false,
    isSideMenu: true,
    child: [
      {
        name: "User Lists",
        path: "/userList",
        icon: Person,
      },
    ]
  },
  // {
  //   path:'#',
  //   id:'marketplace-settings',
  //   name:'Settings',
  //   isSideMenu:true,
  //   icon:List,
  //   isEdit:false,
  //   type:'multi',
  //   child: [
  //     // {
  //     //   name:'Marketplace Settings',
  //     //   path:'/market-place',
  //     //   icon:Person
  //     // },
  //     {
  //       name:'Presale Settings',
  //       path:'/presale',
  //       icon:Person
  //     }
  //   ]
  // }

];

export default dashboardRoutes;
