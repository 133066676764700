// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

export const getAllAdmin = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': config.API_URL+`/adminapi/admin`
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result    
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const addNewAdmin = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': config.API_URL+`/adminapi/admin`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getcategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/categorydetail`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const editAdmin = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': config.API_URL+`/adminapi/admin`,
            data
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getSingleAdmin = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': config.API_URL+`/adminapi/singleAdmin/${id}`
        });
        return {
            status: "success",
            loading: false,
            result: respData.data.result
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const deleteAdmin = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': config.API_URL+`/adminapi/deleteAdmin/${id}`
        });
        return {
            status: respData.data.status,
            message: respData.data.message
        }
    } catch (err) {
        return {
            status: "failed",
            error: err.response.data.errors
        }
    }
}