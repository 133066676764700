// import axios
import axios from 'axios';

// import lib
import config from '../lib/config';
import { getAuthToken } from '../lib/localStorage'

axios.defaults.headers.common['Authorization'] = getAuthToken();

// export const getDashboardCount = async () => {
//     try {
//         let respData = await axios({
//             'method': 'get',
//             'url': `${config.baseUrl}/api/dashboardCount`,
//         });
//         return {
//             status: "success",
//             loading: false,
//             result: respData.data.result
//         }
//     }
//     catch (err) {
//         return {
//             status: "failed",
//             loading: false,
//             error: err.response.data.errors
//         }
//     }
// }

export const getTradeCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/totalCount`,
        });
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            // error: err.response.data.errors
        }
    }
}

export const getTokenCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/tokenCount`,
        });
        console.log(respData,'ddddrespData')
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getBidderCount = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/bidderCount`,
        });
        console.log(respData.data,'dataaaaaaaaaaa')
        return {
            status: "success",
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response.data.errors
        }
    }
}