import React, { useEffect, useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { toastAlert } from '../../lib/toastAlert';
import ReCAPTCHA from "react-google-recaptcha"; 
// import action
import { setCurrentUser, login } from './../../actions/users';

// import lib
import validation from './Validation';
import isEmpty from '../../lib/isEmpty';
import config from '../../actions/config';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textDanger: {
    color: "#f32828",
  }
}));


const initialFormValue = {
  email: '',
  password: '',
}


export default function SignIn() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const [formValue, setFormValue] = useState(initialFormValue);
  const [toched, setToched] = useState({});
  const [validateError, setValidateError] = useState({});
  const recaptchaRef = React.createRef();
  const [captchaErr, setCaptchaError] = useState(false);

  const {
    email,
    password,
    tfaCode
  } = formValue

  // function

      useEffect(()=>{
          const urls = config.ADMIN_URL
      },[])

  async function handleFormSubmit(){
    setValidateError({})
    setCaptchaError(false);
    const recaptchaValue =  recaptchaRef.current.getValue();
    let err = await validation(formValue);
    if(err && err!=null && err !=undefined && err !="" && Object.keys(err).length != 0){
      setValidateError(err);
    }else{
      if(!recaptchaValue){
        setCaptchaError(true);
      } else{
        let reqData = {
          email,
          password,
          tfaCode
        }
        let { error, result } = await login(reqData, dispatch);
        console.log(error);
        toastAlert('error', error, 'adminLogin')
        console.log(reqData, 'reqData');
        if (isEmpty(error)) {
          setFormValue(initialFormValue)
          history.push('/sfgytndvfger/Dashboard')
          dispatch(setCurrentUser(result))
        } else {
          setValidateError(error);
        }
      }
    }
  }
  const handleBlur = (e) => {
    const { name } = e.target;
    setToched({ ...toched, ...{ [name]: true } })
  }

  const handleChange = async (e) => {
    setCaptchaError(false);
    setValidateError({})
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
    setValidateError(validation(formData))
  }

  useEffect(() => {
  }, [])


  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign In
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
            tabindex="-1"
            value={email}
          />
          {
            toched.email && validateError.email && <p className="error-message">{validateError.email}</p>
          }
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            onChange={handleChange}
            onBlur={handleBlur}
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
          />
          {
            toched.password && validateError.password && <p className="error-message">{validateError.password}</p>
          }
          <div className="form-group text-center mt-3">
            <ReCAPTCHA
              sitekey={config.Recaptchakey}
              ref={recaptchaRef}
            />
            <span style ={{color: "red"}}>{captchaErr &&  "ReCAPTCHA Required!"}</span>
          </div>
          <Button
            type="button"
            fullWidth
            variant="contained"
            color="primary"
            onClick={()=>handleFormSubmit()}
            className={classes.submit + " mt-2"}
            disabled={!isEmpty(validateError)}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/sfgytndvfger/Forgot" variant="body2">
                Forgot password?
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
      </Box>
    </Container>
  );
}
