

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Switch from '@material-ui/core/Switch';
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../actions/config.js"  
import Web3 from 'web3';
import '@metamask/legacy-web3'
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'


import ABI from '../../ABI/marketplaceABI'


import {getcmsdetails , cmsupdate } from "../../actions/users";


var network = config.network;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  royality: "",
  service: "",
  service1: "",
  USD: "",
  bidfee:"",
};

const useStyles = makeStyles(styles);

export default function MarketPlace(props) {
  const classes = useStyles();
  const history = useHistory();
  const [toched, setToched] = useState({});

  const dispatch = useDispatch();

  const [address,setAddress] = useState([])
  const [status,setStatus] = useState(false)
  const [walletBalance , setWalletBalance] = useState()

        const connectWallet = async () => {
            if(window.web3.currentProvider){
                console.log(window.web3.currentProvider,'Curr');
                console.log('MetaMask Detected');
                try {
                    const account = window.web3.currentProvider
                    .request({
                        method:'eth_requestAccounts'
                    })
                    .then(async (result)=>{
                        localStorage.setItem('walletAddress',result);
                        setAddress(result)
                    })
                    
                } catch (err) {
                    console.log(err,'errrr');
                }
            } else {
                console.log('Download MetaMask');
            }
        }


        const contractHandle = async () => {
            let contractAddress = config.tradecontract ;
            let web3 = new Web3(window.ethereum)
            if(window.web3 && window.web3.currentProvider && window.web3.currentProvider.networkVersion == config.network){
              let Contract = new web3.eth.Contract(ABI,contractAddress)   
              console.log(Contract,'Contact');
              console.log(Contract.methods,'mmmm');
              let status = await Contract.methods.getApprovalStatus().call();
              console.log(status,'sts');
              const update = await Contract.methods.updatebuybackAdminAddress(!status).send({ from: address[0] });
              console.log(update,'upp');
              status = await Contract.methods.getApprovalStatus().call();
              console.log(status,'sts1');
              setStatus(status)
            }else{
              toastAlert("error", "Please choose Mainnet", "fee");
            }
        }

useEffect(() => {connectWallet()},[])

  return (
    <div>
      <div id="page-content-wrapper">
        <div className="container-fluid">
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <form className={classes.form} noValidate >
                  <CardHeader color="primary">
                  <h4 className={classes.cardTitleWhite}>Market Place Settings</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                    <Button color="primary" type="button" onClick={contractHandle}>Contract Deploy</Button>
                    <Card variant="outlined" style={{padding:'20px',paddingBottom:'20px'}}>
                        <div><span style={{fontSize:'16px',fontWeight:'500'}}>Your Address : </span>
                        <bold style={{fontWeight:'600',fontSize:'18px'}}>{localStorage.getItem('walletAddress')}</bold>
                        </div>
                        </Card>
                      <Card style={{padding:'20px',paddingBottom:'4px'}}>
                        {status == true ? <h5 style={{fontWeight:'500'}}>Access Permited</h5> : <h5 style={{fontWeight:'500'}}>Access Denied</h5>}
                      </Card>
                    </GridItem>
                    </GridContainer>
                  </CardBody>
                </form>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
