

import React, { useState, useEffect } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import PhoneInput from "react-phone-input-2";

import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardBody from "components/Card/CardBody.js";
import Switch from '@material-ui/core/Switch';
import CardFooter from "components/Card/CardFooter.js";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import config from "../../actions/config.js"  
import Web3 from 'web3';
import '@metamask/legacy-web3'
import presaleabi from '../../ABI/presaleABI';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert'


import {updatefeeSetting, getfees , enablewhitelist} from "../../actions/users";

var network = config.network;

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

// toaster config
toast.configure();
let toasterOption = {
  position: "top-right",
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

const initialFormValue = {
  royality: "",
  service: "",
  service1: "",
  USD: "",
  bidfee:"",
};

const useStyles = makeStyles(styles);

export default function Categoryadd(props) {
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch();
  const [owneraddr, setowneraddr] = useState("");
  const [salestatus,setsalestatus] = useState(false);
  const [claimstatus,setclaimstatus] = useState(false);
  const [checkedstatusclaim,setcheckedstatusclaim] = useState(false);
  const [checkedstatussale,setcheckedstatussale] = useState(false);

  useEffect(() => {
    getpresale();
  }, [])

  async function updatelist(key){
    if( key == "sale" ){
      setsalestatus(true)
      let contractAddress = config.presalecontract ;
      let web3 = new Web3(window.ethereum)
      if(web3){
        await window.ethereum.enable();
        web3 = new Web3(window.ethereum)
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if(network == config.network){
          let Contract = new web3.eth.Contract(presaleabi,contractAddress)   
          let owneraddress = await Contract.methods.owner().call();
          if((owneraddress).toLowerCase() == (result[0]).toLowerCase()){
           Contract.methods.saleClose(checkedstatussale).send({ from: result[0] })  
           .then(async (result) => {  
              setsalestatus(false)
              toastAlert("success", "Updated Successfully", "success");
            }).catch((error) => { 
             setsalestatus(false)
             toastAlert("error", "Failed Try Again Later", "fee");
            }) 
          }else{
            setsalestatus(false)
            toastAlert("error", "Please Login Admin Address", "fee");
          }
        }else{
          setsalestatus(false)
          toastAlert("error", "Please choose Mainnet", "fee");
        }
      }else{
        setsalestatus(false)
        toastAlert("error", "Please Add Metamask External", "fee");
      }
    }else if( key == "claim"){
      setclaimstatus(true)
      let contractAddress = config.presalecontract ;
      let web3 = new Web3(window.ethereum)
      if(web3){
        await window.ethereum.enable();
        web3 = new Web3(window.ethereum)
        var network = await web3.eth.net.getId();
        var result = await web3.eth.getAccounts();
        if(network == config.network){
          let Contract = new web3.eth.Contract(presaleabi,contractAddress)   
          let owneraddress = await Contract.methods.owner().call();
          if((owneraddress).toLowerCase() == (result[0]).toLowerCase()){
           Contract.methods.setClaim(checkedstatusclaim).send({ from: result[0] })  
           .then(async (result) => {  
              setclaimstatus(false)
              toastAlert("success", "Updated Successfully", "success");
            }).catch((error) => { 
             setclaimstatus(false)
             toastAlert("error", "Failed Try Again Later", "fee");
            }) 
          }else{
            setclaimstatus(false)
            toastAlert("error", "Please Login Admin Address", "fee");
          }
        }else{
          setclaimstatus(false)
          toastAlert("error", "Please choose Mainnet", "fee");
        }
      }else{
        setclaimstatus(false)
        toastAlert("error", "Please Add Metamask External", "fee");
      }
    }
  }

  const checkchangeclaim = async (e) => {
    setcheckedstatusclaim(!checkedstatusclaim)
  }

  const checkchangesale = async (e) => {
    setcheckedstatussale(!checkedstatussale)
  }

  async function getpresale() {
    let contractAddress = config.presalecontract ;
    let web3 = new Web3(window.ethereum)
    if(web3){
      var network = await web3.eth.net.getId();
      if(network == config.network){
        let Contract = new web3.eth.Contract(presaleabi,contractAddress)   
        let status = await Contract.methods.isClaimAndWithdraw().call();
        setcheckedstatusclaim(status)
        console.log(status,"=======================fffffff")
        const update = await Contract.methods.saleEnd().call();
        setcheckedstatussale(update)
        console.log(update,"=======================ddddddddd")
      }else{
        toastAlert("error", "Please choose Mainnet", "fee");
      }
    }else{
        toastAlert("error", "Please Add Metamask External", "fee");
      }
  }
  
  let formdata = {};

  return (
    <div>
      <div id="page-content-wrapper">
        <div className="container-fluid">

          <h3 className="mt-2 text-secondary">Presale Settings</h3>

          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Card>
                <form className={classes.form} noValidate>
                  <CardHeader color="primary">
                    <h4 className={classes.cardTitleWhite}>Update Presale Settings</h4>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      <div class="custom-control custom-switch">
                        <input type="checkbox" onChange={checkchangesale} class="custom-control-input" id="customSwitch1" checked={checkedstatussale}/>
                        <label class="custom-control-label" for="customSwitch1">Sale Close ON/OFF</label>
                      </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                  {salestatus == false ?
                    <Button type="button" onClick={claimstatus == false ? ()=>updatelist("sale"):""} color="primary" type="button">Update</Button>
                  : salestatus == true &&
                    <Button type="button" color="primary" type="button">In Progress</Button>
                  }
                  </CardFooter>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                      <div class="custom-control custom-switch">
                        <input type="checkbox" onChange={checkchangeclaim} class="custom-control-input" id="customSwitch" checked={checkedstatusclaim}/>
                        <label class="custom-control-label" for="customSwitch">Claim ON/OFF</label>
                      </div>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter>
                  {claimstatus == false?
                    <Button type="button" onClick={salestatus == false ? ()=>updatelist("claim"):""} color="primary" type="button">Update</Button>
                  : claimstatus == true && 
                    <Button type="button" color="primary" type="button">In Progress</Button>
                  }
                  </CardFooter>
                </form>
                 
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
