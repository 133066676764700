// import package
import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

// @material-ui/core 
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel, InputLabel } from '@material-ui/core';
import {
    CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
    CheckBox as CheckBoxIcon
} from '@material-ui/icons';

// import InputLabel from "@material-ui/core/InputLabel";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// import action
import { getSingleAdmin, editAdmin } from './../../actions/admin';

// import lib
import routes from '../../routes';
import { toastAlert } from '../../lib/toastAlert';
import isEmpty from '../../lib/isEmpty';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    }
};

const initialFormValue = {
    'name': "",
    'email': "",
    'restriction': []  //restriction(path, isWriteAccess)
}

const useStyles = makeStyles(styles);

const AdminEdit = (props) => {
    const classes = useStyles();
    const history = useHistory();
    const { userId } = useParams();

    // state
    const [toched, setToched] = useState({});
    const [formValue, setFormValue] = useState(initialFormValue);
    const [validateError, setValidateError] = useState({});

    const { name, email, password, restriction } = formValue;

    // function
    const handleChange = (e) => {
        const { name, value } = e.target;
        let formData = { ...formValue, ...{ [name]: value } }
        setFormValue(formData)
    }

    const handleRestriction = (data) => {
        let index = restriction.findIndex((el => el.path == data.path))
        if (index >= 0) {
            if (data.isRestricted == 'multi') {
                let newData = []
                restriction.filter((el, key) => {
                    let childData = data.child.find((item => item.path == el.path))
                    if (!(childData || key == index)) {
                        newData.push(el)
                    }
                })

                let formData = {
                    ...formValue, ...{
                        'restriction': newData
                    }
                }
                setFormValue(formData);
                return
            } else {
                let formData = {
                    ...formValue, ...{
                        'restriction': restriction.filter((el, key) => key != index && el)
                    }
                }
                setFormValue(formData);
                return
            }
        } else {
            if (data.isRestricted == 'multi') {
                let newRestriction = [{
                    'path': data.path,
                    'isWriteAccess': false
                }]

                data.child.filter(item => {
                    newRestriction.push({
                        'path': item.path,
                        'isWriteAccess': false
                    })
                })

                const newRestrictionValue = [
                    ...restriction, ...newRestriction
                ]
                console.log("---formData", newRestrictionValue)
                let formData = {
                    ...formValue, ...{
                        'restriction': newRestrictionValue
                    }
                }

                setFormValue(formData);
                return
            } else {
                const newRestriction = [
                    ...restriction, ...[{
                        'path': data.path,
                        'isWriteAccess': false
                    }]
                ]
                let formData = {
                    ...formValue, ...{
                        'restriction': newRestriction
                    }
                }
                setFormValue(formData);
                return
            }
        }
    }

    const handleWriteAccess = (data) => {
        let index = restriction.findIndex((el => el.path == data.path))
        if (index >= 0) {
            let formData = {
                ...formValue, ...{
                    'restriction': restriction.filter((el, key) => {
                        if (key == index) {
                            el['isWriteAccess'] = !el.isWriteAccess
                        }
                        return el;
                    })
                }
            }
            setFormValue(formData);
            return
        }
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        let reqData = {
            'adminId': userId,
            name,
            email,
            restriction
        }
        try {
            const { status, loading, error, result } = await editAdmin(reqData);
            if (status == 'success') {
                history.push('/list')
                toastAlert('success', result.messages, 'editAdmin')

            }
        } catch (err) { }
    }

    const renderRestrictionList = () => {
        let arr = [];
        routes && routes.length > 0 && routes.map((el) => {
            if (el.isSideMenu) {
                if(el.name !=='Admin Management'){

                    if (el.type == 'multi') {
                        el.child.map((item) => {
                            if (item.isRestricted != 'common') {
                                let isChecked = false, isWriteChecked = false, isWriteDisabled = true;
                                let restrictionData = restriction.find((restItem => restItem.path == item.path));
                                if (restrictionData) {
                                    isChecked = true
                                    isWriteDisabled = false
                                    isWriteChecked = restrictionData.isWriteAccess;
                                }
    
                                arr.push(
                                    <div>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={isChecked}
                                                    onClick={() => handleRestriction(item)}
                                                />
                                            }
                                            label={item.name}
                                        />
                                        {/* <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        disabled={isWriteDisabled}
                                                        checked={isWriteChecked}
                                                        onClick={() => handleWriteAccess(item)}
                                                    />
                                                }
                                                label={'isWrite'}
                                            /> */}
                                    </div>
                                )
                            }
                        })
                    } else {
                        let isChecked = false, isWriteChecked = false, isWriteDisabled = true;
                        let restrictionData = restriction.find((restItem => restItem.path == el.path));
                        if (restrictionData) {
                            isChecked = true
                            isWriteDisabled = false
                            isWriteChecked = restrictionData.isWriteAccess;
                        }
    
                        arr.push(
                            <div>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={isChecked}
                                            onClick={() => handleRestriction(el)}
    
                                        />
                                    }
                                    label={el.name}
                                />
                                {/* <FormControlLabel
                                            control={
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    disabled={isWriteDisabled}
                                                    checked={isWriteChecked}
                                                    onClick={() => handleWriteAccess(el)}
                                                />
                                            }
                                            label={'isWrite'}
                                        /> */}
                            </div>
                        )
                    }
                }
               
            }
        })
        return arr;
    }

    const fetchAdminDetail = async () => {
        try {
            const { status, loading, error, result } = await getSingleAdmin(userId)

            console.log(result,'resultresultresult');
            if (status == 'success') {
                let formData = {
                    'name': result.name,
                    'email': result.email,
                    'restriction': result.restriction
                }
                setFormValue(formData);
            }
        } catch (err) { }
    }

    useEffect(() => {
        fetchAdminDetail()
        if (routes && routes.length > 0) {
            let commonData = []
            routes.filter((el) => {
                if (el.isRestricted == 'common') {
                    commonData.push({
                        'path': el.path,
                        'isWriteAccess': false
                    })
                }
            })

            if (commonData && commonData.length > 0) {
                let formData = {
                    ...formValue, ...{
                        'restriction': commonData
                    }
                }
                setFormValue(formData);
            }
        }

    }, [])

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <form className={classes.form} noValidate onSubmit={handleFormSubmit}>
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Edit Admin</h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Name"
                                            name="name"
                                            value={name}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                        {
                                            validateError.name && <span className={classes.textDanger}>{validateError.name}</span>
                                        }
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={6}>
                                        <CustomInput
                                            labelText="Email"
                                            name="email"
                                            value={email}
                                            onChange={handleChange}
                                            formControlProps={{
                                                fullWidth: true
                                            }}
                                        />
                                        {
                                            validateError.email && <span className={classes.textDanger}>{validateError.email}</span>
                                        }
                                    </GridItem>

                                </GridContainer>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <InputLabel>Restriction</InputLabel>
                                        {renderRestrictionList()}

                                    </GridItem>
                                </GridContainer>
                            </CardBody>
                            <CardFooter>
                                <Button color="primary" type="submit">Update</Button>
                            </CardFooter>
                        </form>
                    </Card>
                </GridItem>
            </GridContainer>
        </div>
    );
}

export default AdminEdit;