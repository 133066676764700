import React, { useRef, useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { toast } from 'react-toastify';
import { toastAlert } from '../../lib/toastAlert';
import config from 'actions/config';
import ReCAPTCHA from "react-google-recaptcha"; 
import validation from './validation';
// import img from "assets/img/coin_img.png";
// import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';


import { forgotPassword } from './../../actions/users';

import isEmpty from '../../lib/isEmpty';


// toaster config
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

// function Copyright() {
//   return (
//     <Typography variant="body2" color="textSecondary" align="center">
//       {'Copyright © '}
     
//         EOOON
//      {' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }


const useStyles = makeStyles((theme) => ({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
  
    textDanger: {
      color: "#f32828",
    }
  }));

  // props
  const initialFormValue = {
    'email': '',
  }

export default function ForgotPassword() {

//   const history = useHistory();
//   const dispatch = useDispatch();


  const classes = useStyles();
  const history = useHistory();
  const [formValue, setFormValue] = useState(initialFormValue);
  const [validateError, setValidateError] = useState({});
  const recaptchaRef = React.createRef();
  const [captchaErr, setCaptchaError] = useState(false);
  const { email } = formValue;

  // function
  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    let formData = { ...formValue, ...{ [name]: value } }
    setFormValue(formData)
  }

  async function handleFormSubmit(){
    setCaptchaError(false);
    setValidateError({});
    const recaptchaValue = recaptchaRef.current.getValue();
    let err = await validation(formValue);
    if(err && err!=null && err !=undefined && err !="" && Object.keys(err).length != 0){
      setValidateError(err);
    }else{
      if(!recaptchaValue){
        setCaptchaError(true);
      } else{
        let reqData = {
            email
        }
        let result = await forgotPassword(reqData);
        if(result && result.result && result.result.status && result.result.status== true){
          toast.success("Recovery Mail sent to you Email , kindly verify mail to proceed further",toasterOption) 
          history.push('/sfgytndvfger/Login');
        }else{
          var validateError = {};
          validateError.email = result.result.email
          setValidateError(validateError);
        }
      }
    }
  }
  
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
         Forgot Password
        </Typography>
          <form className={classes.form} noValidate>
            <TextField
              onChange={handleChange}
              value={email}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              tabindex="-1"
            />
             {
                validateError.email && <span className={classes.textDanger}>{validateError.email}</span>
            }
            <div className="form-group text-center mt-3">
              <ReCAPTCHA
                sitekey={config.Recaptchakey}
                ref={recaptchaRef}
              />
            <span style ={{color: "red"}}>{captchaErr &&  "ReCAPTCHA Required!"}</span>
            </div>
           
            <Button
              type="button"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit+ " mt-2"}
              onClick={()=>handleFormSubmit()}
            >
              Send Mail
            </Button>
            <Grid container>
            <Grid item xs>
              <Link href="/sfgytndvfger/Login" variant="body2">
                Login
              </Link>
            </Grid>
          </Grid>
          </form>
        </div>
        </Container>
   
  );
}