import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { collectorlist, removevip, addvip, deleteuser, undeleteuser, checkwhitelist, rejectwhitelist, removeapprove, updateapprove } from '../../actions/users';
import Search from "@material-ui/icons/Search";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import customInputStyle from "assets/jss/material-dashboard-react/components/customInputStyle.js";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import LockIcon from '@material-ui/icons/Lock';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { Link, useHistory } from "react-router-dom";
import EditIcon from '@material-ui/icons/Edit';
import StarIcon from '@material-ui/icons/Star';
import { toastAlert } from '../../lib/toastAlert'
import config from "../../actions/config.js"
import Web3 from 'web3';
import '@metamask/legacy-web3'
import SearchBar from "material-ui-search-bar";

import { toast } from 'react-toastify';
toast.configure();
let toasterOption = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
}

const rows = [
    // getData('')
    //   createData('firstName', 'lastName', 'Email', 'phoneNo', 'accountType')
    //   createData('Donut', 452, 25.0, 51, 4.9),
];


const headCells = [
    { id: 'Address', numeric: false, disablePadding: true, label: 'Address' },
    // { id: 'Buy Count', numeric: true, disablePadding: false, label: 'Buy Count' },
    // { id: 'Create Count', numeric: true, disablePadding: false, label: 'Create Count' },
    // { id: 'Sell Count', numeric: true, disablePadding: false, label: 'Sell Count' },
    { id: 'Link', numeric: true, disablePadding: false, label: 'Link' },
    { id: 'Action', numeric: true, disablePadding: false, label: 'Action' },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.numeric ? 'center' : 'center'}
                        padding={headCell.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 76%',
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected } = props;

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onChangeterm: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    paper: {
        width: '100%',
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
}));


const customStyle = makeStyles(customInputStyle);

export default function EnhancedTable() {
    const classes = useStyles();
    const customStyles = customStyle();

    const classesSearch = useToolbarStyles();
    const [data, setData] = React.useState(0);
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [userDetails, setUser] = useState();
    const [searchQuery, setSearchQuery] = React.useState("");
    const [typingTimeout, setTypingTimeout] = useState(0)
    const [status, setstatus] = useState(false)
    const [keyselected, setkeyselected] = React.useState("Select..");
    const [searched, setSearched] = React.useState("");
    const [usercopy, setusercopy] = useState();
    const [userCount,setuserCount]=useState(0)

    function editR(id) {
        // alert(e.target.id);
        if (id != '') {
            window.location = "/sfgytndvfger/useredit/" + id;
        }

    }
    function lock() {

    }
    const handleChange = (event) => {
        const { name, value } = event.target;
        const self = this;
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }

        setSearchQuery(value)
        setTypingTimeout(setTimeout(function () {
            getAll({ 'search': value });
        }, 1000))
    }

    const handleRequestSort = (event, property) => {
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rows.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };
    useEffect(() => {
        getAll();
    }, [page,rowsPerPage])

    const requestSearch = (searchedVal) => {
        console.log(searchedVal,'searchedValsearchedVal')
        const filteredRows = usercopy.filter((row) => {
            return row.address.toLowerCase().includes(searchedVal.toLowerCase());
        });
        setUser(filteredRows);
    };

    const cancelSearch = () => {
        setSearched("");
        requestSearch(searched);
    };


    async function removetoFavour(iiid) {
        var data = {
            id: iiid
        }
        const res = await removevip(data);
        toastAlert('success', "Successfully Removed from List", 'currency')
        getAll();
    }

    async function addtoFavour(iiid) {
        var data = {
            id: iiid
        }
        const res = await addvip(data);
        toastAlert('success', "Successfully Added", 'currency')
        getAll();
    }

    async function UnDelete_use(row) {
        const details = row
        let data = {
            "id": row.address
        }
        await undeleteuser(data);
        window.location.reload();
    }

    async function Delete_use(row) {
        const details = row
        let data = {
            "id": row.address
        }
        await deleteuser(data);
        window.location.reload()
    }

    async function approve(row) {
        const details = row
        let data = {
            "id": row.address
        }
        await updateapprove(data);
        window.location.reload()
    }

    async function remove(row) {
        const details = row
        let data = {
            "id": row._id.address
        }
        await removeapprove(data);
        window.location.reload()
    }



    const getAll = async (search = {}) => {
        let filterData = search;
        if (filterData.search == '') {
            filterData = {}
        }
        const payload={
            filterData,
            page,
            rowsPerPage
        }
        var res = await collectorlist(payload);
        console.log(res,'resss');
        if (res && res.data && res.data.data && res.data.data.length > 0) {
            setUser(res.data.data);
            setusercopy(res.data.data);
            setuserCount(res.data.TotalLength.length)
        }
    }

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        console.log(newPage);
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    async function keychange(val) {
        console.log(usercopy, "=====usercopyusercopy")
        setkeyselected(val);
        if (val != "All") {
            let checkcon = val == "UnBanned User" ? 0 : 1;
            const filteredRows = usercopy.filter((row) => {
                return row.deleted == checkcon;
            });
            setUser(filteredRows);
        } else {
            setUser(usercopy)
        }
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>

                <Typography className={classesSearch.title} variant="h6" id="tableTitle" component="div">
                    Users Details
                </Typography>
                <div className={classesSearch.searchWrapper}>
                    <FormControl
                        className={classesSearch.margin + " " + classesSearch.search + " " + customStyles.formControl}
                    >

                    </FormControl>
                </div>
                <div className="table_filter">
                    <SearchBar
                        value={searched}
                        onChange={(searchVal) => requestSearch(searchVal)}
                        onCancelSearch={() => cancelSearch()}
                        className=""
                        placeholder='search User address'

                    />
                    <div class="dropdown">
                        <button class="btn btn-light dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {keyselected}
                        </button>
                        <div class="dropdown-menu" aria-labelledby="Select">
                            <a class="dropdown-item" href="#" onClick={() => keychange("All")}>All</a>
                            <a class="dropdown-item" href="#" onClick={() => keychange("Banned User")}>Banned User</a>
                            <a class="dropdown-item" href="#" onClick={() => keychange("UnBanned User")}>UnBanned User</a>
                        </div>
                    </div>
                </div>
                <TableContainer>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        // size={dense ? 'small' : 'medium'}
                        aria-label="enhanced table"
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            //   order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={rows.length}
                        />
                        <TableBody>
                            {userDetails && userDetails.map((row, index) => {
                                console.log(row,'rowrow');
                                return (
                                    <TableRow>
                                        <TableCell align="center" component="th"
                                            scope="row" padding="none">
                                            {row.address}
                                        </TableCell>

                                       
                                        <TableCell align="center"><a href={config.frontUrl + "profile/" + row.address} target="_blank">Link</a></TableCell>
                                        {/* <TableCell align="center">
                                         {row.verificationstatus && row.verificationstatus=="pending"?
                                            <Button type="button"  onClick={() => approve(row)}>Approve</Button>
                                        :row.verificationstatus && row.verificationstatus=="verified"?
                                            <Button type="button" onClick={() => remove(row)}>Remove</Button>
                                        :
                                            <Button type="button"  onClick={() => approve(row)}>Approve</Button>
                                        } 
                                        </TableCell>*/}
                                        <TableCell align="center">
                                            {row.deleted == 1 ?
                                                <Button type="button" className="ban_btn" onClick={() => Delete_use(row)}>Ban</Button>
                                                :
                                                <Button type="button" className="unban_btn" onClick={() => UnDelete_use(row)}>UnBan</Button>
                                            }
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={userCount?userCount:0}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}