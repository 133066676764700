import axios from 'axios';

// import action
import { decodeJwt } from './jsonWebToken';

// import lib
import config from '../lib/config';
import setAuthToken from '../lib/setAuthToken';

import {
    SET_CURRENT_USER
} from '../constant';


// import constant
// import {
//     SET_CURRENT_USER
// } from '../constant';

export const getAllrequest = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/whitelistrequest`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const collectorlist = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.API_URL}/adminapi/collectorlist`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const updateapprove = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.API_URL}/adminapi/updateapprove`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const removeapprove = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.API_URL}/adminapi/removeapprove`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}


export const updateterms = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/api/token/updateterms`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getterms = async () => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/api/token/getterms`,
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const cmsupdate = async (Payload) => {
  try {
    let Resp = await axios({
      'method': 'post',
      'url': `${config.baseUrl}/api/token/cmsupdate`,
      'data': Payload
    })
    return Resp;
  }
  catch (err) {
    return {
    // error: err.response.data
    }
  }
}

export const getsinglecms = async (iid) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getsinglecms/` + iid,
        });
console.log(respData.data.data,'ddddd')
        return {
            status: "success",
            loading: false,
            result: respData
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response
        }
    }
}

export const getfees = async (data) => {
       try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getfee`,
        });
        return {
            status: "success",
            loading: false,
            result: respData
        }
    } catch (err) {
        return {
            status: "failed",
            loading: false,
            error: err.response
        }
    }
}

export const getcmsdetails = async () => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/getcmsdetails`,
            headers: {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const enablewhitelist = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/enablewhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updatefeeSetting = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/updatefeeSetting`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const approvewhitelist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/approvewhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const getAllwhitelist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/getAllwhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const rejectwhitelist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/rejectwhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const checkwhitelist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/checkwhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const addwhitelist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/addwhitelist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}



export const updateblockCms = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/updateblockCms`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const login = async (data, dispatch) => {
    try {
        console.log(data,'dataa')
        let respData = await axios({
            'method': 'post',
            'url': config.API_URL+`/adminapi/login`,
            data
        });

        console.log(respData,'ressss');
        localStorage.setItem('admin_token', respData.data.token);
        setAuthToken(respData.data.token)
        decodeJwt(respData.data.token, dispatch)

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getblockcms = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/token/getblockcms`,
            headers: {
                'Authorization': localStorage.admin_token
            },
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllPairs = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairs`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

// Update Currency Id 
// export const updateCurrency = async (data,headers) => {
//     console.log(data,"dataaaaaaaaaaaaaaaaaaaa")
//     try {
//         let bodyFormData = new FormData();
//         bodyFormData.append('currencyName', data.currencyName);
//         bodyFormData.append('currencySymbol', data.currencySymbol);
//         bodyFormData.append('currencyType', data.currencyType);
//         bodyFormData.append('photo', data.photo);
//         bodyFormData.append('decimals', data.decimals);
//         bodyFormData.append('fee', data.fee);
//         bodyFormData.append('minimum', data.minimum);
//         bodyFormData.append('contractAddress',data.contractAddress);
//         bodyFormData.append('userId', data.userId);
//         var headersDefault = {
//             'Content-Type': 'multipart/form-data',
//             'Authorization': localStorage.admin_token
//         };
//         var finalHeaders = Object.assign(headersDefault, headers);

//         console.log(bodyFormData,"checking body formdata");
//         console.log(finalHeaders,"finalHeaders");

//         let respData = await axios({
//             'method': 'post',
//             'url': `${config.API_URL}/adminapi/updateCurrency`,
//             headers: finalHeaders,
//             data: bodyFormData
//         });

//     /* Merge defaults and options, without modifying defaults */
//         console.log(respData,'checking ResponseData');
//         return {
//             loading: true,
//             result: respData.data.result
//         }

//     }
//     catch (err) {
//         return {
//             loading: false,
//             error: err
//         }
//     }
// }

export const addFaq = async (data) => {

    try {
        console.log(data, "checking data");
        console.log(config.API_URL,'url')
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/addFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateFaq = async (data) => {

    try {
        console.log(data, "checking data");
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/updateFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData, 'checking ResponseData');
        return {
            loading: true,
            result: respData.data.result
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const getBidslist = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getBidslist`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
    
}

export const gettokenlistburn = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/gettokenlistburn`,
            params: filterData
        });
        console.log(respData,'response')
        return {
            loading: false,
            result: respData.data.tokenData

        }
    }

    catch (err) {

        return {
            loading: false,
            error: err.response.data.errors
        }
        console.log("not ok")
    }
}

export const getreportlist = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getreportlist`,
            params: filterData
        });
        console.log(respData,'response')
        return {
            loading: false,
            result: respData.data.tokenData
        }
    }

    catch (err) {

        return {
            loading: false,
            error: err.response.data.errors
        }
        console.log("not ok")
    }
}

export const getreportcollectionlist = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getreportcollectionlist`,
            params: filterData
        });
        console.log(respData,'response')
        return {
            loading: false,
            result: respData.data.tokenData
        }
    }

    catch (err) {

        return {
            loading: false,
            error: err.response.data.errors
        }
        console.log("not ok")
    }
}

export const updateverification = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/contractverification`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const collectionlist = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/collectionlist`,
            params: filterData
        });
        return {
            loading: false,
            result: respData
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
        console.log("not ok")
    }
}



export const gettokenlist = async (filterData, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/gettokenlist`,
            params: filterData
        });
        console.log(respData,'response')
        return {
            loading: false,
            result: respData

        }
    }

    catch (err) {

        return {
            loading: false,
            error: err.response.data.errors
        }
        console.log("not ok")
    }
}

export const getcatory = async (filterData, dispatch) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/getcatory`,
            params: filterData
        });
        return {
            loading: false,
            result: respData.data

        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const gettokendata = async (id) => {
    console.log('yes', id)
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/gettokendata/` + id,
        });
        return {
            loading: false,
            result: respData.data.tokenData
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const deleteToken = async (id, dispatch) => {
    //console.log('yes');
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/deleteToken/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllverifylist = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/getAllverifylist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const rejectreq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/rejectreq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const acceptreq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/acceptreq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllprohibited = async (data) => {
    try {
        console.log(data,'result of Datadfdsfdsfdsfdsfa');

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/prohibitedlist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData.data);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

  export const addProhibited = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/addprohibited`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getsingleword = async (data) => {
    try {
        console.log('confirmmmm',data)
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/singleprohibited`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData.data.data);
       return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updateWord = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateWord`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
        console.log('result of Data',  respData.data.data);
       return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const deleteWord = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deleteword`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log(respData.data.message,'successsss')
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllcategory = async (data) => {
    try {
        console.log(data,'result of Datadfdsfdsfdsfdsfa');

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/categorylist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllCms = async (data) => {
    try {
        console.log(data,'cmslistttt');

        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/cmslist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getAllSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/settinglist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const addcategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/addcategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updatecategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/updatecategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
           data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getcategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/categorydetail`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getAllUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/categorylist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
//Get Currency id currency management module

export const getCurrencyId = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getCurrencyId?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getFaqId = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/getFaq/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getPairId = async (data) => {
    try {
        console.log('data', data);
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getPairId?pairid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getUser = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getUsers?userid=` + data,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const updateProfile = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/updateprofile`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const updateUser = async (data) => {
    try {

        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/updateUser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data update Profile', respData);
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}


export const updateEmail = async (data) => {
    try {

        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/updateEmailTemplate`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data update Profile', respData);
        return {
            loading: false,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const forgotPassword = async (data) => {
    try {
        console.log(data,'aaaaa')
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/forgotPassword`,
            data
        });

        return {
            loading: false,
            result: respData.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const changePassword = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/resetPassword`,
            // headers: {
            //     'Authorization': localStorage.admin_token
            // },
            data

        });

        return {
            loading: false,
            result: respData.data
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getLoginHistory = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getLoginHistory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getemaillist = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getemaillist`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getFaqList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/getFaqList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            params: data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}






export const getAddressProof = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllAddress`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data

        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getEmailid = async (id) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/api/getemailid/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.data
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getBankDetails = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/proof/getAllBank`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const UpdateStatus = async (data) => {
    try {
        console.log('result of Data---', data);
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/proof/updateStatus`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
            // data = {
            //     id:"check",
            //     status:3,
            //     role:1
            //     }
        });
        console.log('result of Data', respData);


        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const getadminUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/adminprofile`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log("aaaww",respData)

        return {
            loading: false,
            result: respData.data.data
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const get2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/get2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const update2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/update2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
        console.log(data, '------------checkData');

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const disable2faCode = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/diabled2faCode`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
        console.log(data, '------------checkData');

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}


export const deletecategory = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/deletecategory`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const deleteFaq = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/v2/routesAdmin/deleteFaq`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);
       return {
            loading: false,
            result: respData
        }

    }

    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const getcategorytype = async (token, dispatch) => {
    console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.baseUrl}/v2/routesAdmin/getcategorytype`,
        });

        console.log(respData,"RESdATA")

        return {
            loading: false,
            userValue: respData.data.contactData
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}



//Support page 
export const getSupportList = async (data) => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/supportList`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        console.log('result of Data', respData);

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

//Using post method only Support page Pass id using delete api  

export const deleteSupport = async (id) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/supportDelete/` + id,
            headers: {
                'Authorization': localStorage.admin_token
            },

        });
        console.log('result of Data', respData);
        console.log(id, '------------checkData');

        // localStorage.setItem('admin_token', respData.data.token);

        return {
            loading: false,
            result: respData.data.result
        }

    }
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}
export const getSupportId = async (id, dispatch) => {
    console.log('id', id);

    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/getSupportId/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        console.log(err, 'err');
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}
export const closeticket = async (id, dispatch) => {
    //console.log('yes')
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API}/adminapi/closeticket/` + id,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            loading: false,
            userValue: respData.data.userValue
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const savereply = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API}/adminapi/savereply`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });

        // console.log(respData.data.result,"hsdjfhjksdhfjkdhfjhdsjkghdfjkghjkdfhgjkdfhgjkdfhgjkdhfgjkhdfk");

        return {
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const logout = (history) => {
    localStorage.removeItem('admin_token');
    history.push('/Login')
    setAuthToken('')
}

export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};

export const getSetting = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
        });
        return {
            status: 'success',
            loading: false,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const updateSetting = async (data) => {
    try {
        let respData = await axios({
            'method': 'put',
            'url': `${config.API_URL}/adminapi/setting`,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            status: 'success',
            loading: false,
            messages: respData.data.messages,
            result: respData.data.result,
        }
    }
    catch (err) {
        return {
            status: 'failed',
            loading: false,
            error: err.response.data.errors
        }
    }
}

export const getproductdetails = async (data, dispatch) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/getproductdetails` ,
            'headers': {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: false,
            result: respData
        }
    }
   
    catch (err) {
        var sendErr = '';
        if (err) {
            sendErr = err;
            if (err.response) {
                sendErr = err.response;
                if (err.response.data) {
                    sendErr = err.response.data;
                    if (err.response.data.errors) {
                        sendErr = err.response.data.errors;
                    }
                }
            }
        }
        return {
            loading: false,
            error: sendErr
        }
    }
}

export const BurnField = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/token/BurnField`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const removevip = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/token/removevip`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}


export const undeleteuser = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/undeleteuser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const undeletenft = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/undeletenft`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const deleteuser = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/deleteuser`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const deletenft = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/deletenft`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const deletecollection = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/deletecollection`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}
export const undeletecollection = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/api/adminapi/undeletecollection`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const addvip = async (data) => {

    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.baseUrl}/api/token/addvip`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}

export const removefeatured = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/removefeatured`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}


export const addFeatured = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/addFeatured`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}


export const removetranding = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/removetranding`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}


export const addtranding = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${config.API_URL}/adminapi/addtranding`,
            headers: {
                'Authorization': localStorage.admin_token
            },
            data
        });
        return {
            loading: true,
            result: respData.data
        }

    }
    catch (err) {
        return {
            loading: false,
            error: err
        }
    }
}
